import React from 'react'

import { Link } from 'gatsby'

import './ConfidentSpeakers.scss'

const ConfidentSpeakers = () => (
  <div className="confident-speakers">
    <div className="wrapper">
      <h2>Start your 30-day free trial today</h2>
      <br />
      <Link className="request-demo-button" to="/trial/organization">
        <h2 style={{ marginBottom: 0 }}>Request a Demo</h2>
      </Link>
    </div>
  </div>
)

export default ConfidentSpeakers
